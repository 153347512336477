/**
 * WebApp
 *
 * This is the main container for the project.
 */

// Core imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import LoadingBar from 'react-redux-loading-bar';
import { cssTransition, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

// Style, SEO and settings
import Helmet from 'react-helmet';
import { styled, withTheme } from '@smooth-ui/core-sc';
import GlobalStylesApp from 'theme/globalStylesApp';
import { CloudinaryContext } from 'cloudinary-react';

// Data
import injectReducer from '_platform/src/utils/injectReducer';
import injectSaga from '_platform/src/utils/injectSaga';
import {
  adminMenuRequest,
  programSettingsRequest,
  unitOfMeasureRequest,
} from './actions';
import reducer from './reducer';
import saga from './saga';
import {
  selectMenu,
  selectRoutes,
  selectSettingsPointsUOM,
  selectSettingsProgram,
  selectSettingsSalesUOMs,
  selectUnitOfMeasure,
} from './selectors';
import { SettingsProvider } from './SettingsContext';

import { userProfileRequest } from '_platform/src/containers/App/actions';
import { selectUserProfile } from '_platform/src/containers/App/selectors';

// Additional Components/Containers
import LoadAsync from '_platform/src/utils/LoadAsync';
import PrivateComponent from '_platform/src/utils/PrivateComponent';
import Routes from './Routes';
import LoriFooter from '_platform/src/components/LoriFooter/LoriFooter';
import ScrollToTop from '_platform/src/components/ScrollToTop/ScrollToTop';

const Header = LoadAsync(() =>
  import(/* webpackChunkName: "header" */ 'components/Header/Header')
);
const AdminMenu = LoadAsync(() =>
  import(/* webpackChunkName: "adminMenu" */ 'containers/AdminMenu/AdminMenu')
);

export const UserProfileContext = React.createContext();

const AppWrapper = styled.div`
  /* for child elements */
  display: flex;
  flex-direction: column;

  /* sticky footer */
  flex: 1 0 auto;
`;

const PageWrapper = styled.div`
  /* for child elements */
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;

const ToastifyTransition = cssTransition({
  enter: 'Toastify__slide-enter--top-right',
  exit: 'Toastify__slide-exit--top-right',
  duration: [300, 600],
});

class WebApp extends Component {
  componentDidUpdate(prevProps) {
    // On change of permissions, request the menu
    if (
      (this.props.currentUser.permissions &&
        this.props.currentUser.permissions !==
          prevProps.currentUser.permissions) ||
      this.props.currentUser.userId !== prevProps.currentUser.userId
    ) {
      this.props.onAdminMenuRequest();
    }

    // If the profile is not present, request profile
    if (this.props.currentUser.permissions && !this.props.userProfile) {
      this.props.onUserProfileRequest();
    }

    if (this.props.currentUser.permissions && !this.props.settingsProgram) {
      this.props.onProgramSettingsRequest();
    }

    // Request Unit of Measure - if additional settings are needed convert to request settings
    if (this.props.currentUser.permissions && !this.props.unitOfMeasure) {
      this.props.onRequestUnitOfMeasure();
    }
  }

  render() {
    const { adminMenu, routes, theme } = this.props;

    return (
      <React.Fragment>
        <LoadingBar
          style={{
            left: 0,
            height: '4px',
            backgroundColor: (theme && theme.loadingBarColor) || '#f4bd19',
            position: 'fixed',
          }}
          updateTime={350}
        />
        <Helmet
          titleTemplate={`%s - ${theme.settingsApp.siteName}`}
          defaultTitle={theme.settingsApp.siteName}
        />
        <ToastContainer
          autoClose={4000}
          newestOnTop
          position="bottom-right"
          transition={ToastifyTransition}
        />
        <SettingsProvider
          settings={{
            pointsUOM: this.props.settingsPointsUOM,
            program: this.props.settingsProgram,
            routes: this.props.routes,
            salesUOMs: this.props.settingsSalesUOMs,
            settingsApp: this.props.theme.settingsApp,
          }}
        >
          <UserProfileContext.Provider value={this.props.userProfile}>
            <CloudinaryContext
              cloudName={theme.settingsApp.cloudinaryCloudName}
              uploadPreset={theme.settingsApp.cloudinaryUploadPreset}
              style={{ display: 'flex', flex: '1 0 auto', minHeight: '100%' }} // TODO: Remove this when https://github.com/cloudinary/cloudinary-react/pull/81 is merged/published
            >
              <AppWrapper
                style={{ width: '100%' }} // TODO: Remove this when https://github.com/cloudinary/cloudinary-react/pull/81 is merged/published
              >
                <ScrollToTop>
                  <PrivateComponent>
                    <Header
                      siteName={theme.settingsApp.siteName}
                      siteLogo={theme.settingsApp.siteLogoReversed}
                      siteLogoHeight={theme.settingsApp.siteHeaderLogoHeight}
                      siteLogoMaxHeight={
                        theme.settingsApp.siteHeaderLogoMaxHeight
                      }
                      onLogout={this.props.onLogout}
                    />
                    <AdminMenu menu={adminMenu} />
                  </PrivateComponent>

                  <PageWrapper>
                    <Routes routes={routes} theme={theme} />
                  </PageWrapper>
                </ScrollToTop>
              </AppWrapper>
            </CloudinaryContext>
          </UserProfileContext.Provider>
        </SettingsProvider>
        <LoriFooter />
        <GlobalStylesApp />
      </React.Fragment>
    );
  }
}

WebApp.propTypes = {
  adminMenu: PropTypes.array,
  currentUser: PropTypes.object.isRequired,
  onAdminMenuRequest: PropTypes.func.isRequired,
  onLogout: PropTypes.func, // eslint-disable-line react/require-default-props
  // Using the disable line above as react sometimes doesn't pass through the prop pre-first render
  onProgramSettingsRequest: PropTypes.func.isRequired,
  onRequestUnitOfMeasure: PropTypes.func.isRequired,
  onUserProfileRequest: PropTypes.func.isRequired,
  routes: PropTypes.array,
  settingsPointsUOM: PropTypes.object,
  settingsProgram: PropTypes.object,
  settingsSalesUOMs: PropTypes.array,
  theme: PropTypes.object,
  unitOfMeasure: PropTypes.array,
  userProfile: PropTypes.object,
};

WebApp.defaultProps = {
  adminMenu: undefined,
  routes: undefined,
  settingsPointsUOM: undefined,
  settingsProgram: undefined,
  settingsSalesUOMs: undefined,
  theme: { settingsApp: {} },
  unitOfMeasure: undefined,
  userProfile: undefined,
};

const mapStateToProps = createStructuredSelector({
  adminMenu: selectMenu(),
  routes: selectRoutes(),
  settingsPointsUOM: selectSettingsPointsUOM(),
  settingsProgram: selectSettingsProgram(),
  settingsSalesUOMs: selectSettingsSalesUOMs(),
  unitOfMeasure: selectUnitOfMeasure(),
  userProfile: selectUserProfile(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onAdminMenuRequest: () => dispatch(adminMenuRequest()),
    onProgramSettingsRequest: () => dispatch(programSettingsRequest()),
    onRequestUnitOfMeasure: () => dispatch(unitOfMeasureRequest()),
    onUserProfileRequest: () => dispatch(userProfileRequest()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'webApp', reducer });
const withSaga = injectSaga({ key: 'webApp', saga });

export default compose(
  withTheme,
  withReducer,
  withSaga,
  withConnect
)(WebApp);
